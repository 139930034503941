<template>
  <div class="page">
    <NavBar />
    <div class="project container">
      <h1>{{ project.title }}</h1>
      <div class="img__elt">
        <a :href="project.lien" target="_blank"
          ><img :src="project.image" alt=""
        /></a>
      </div>
      <h2 class="project__subTitle">Étude de cas</h2>
      <p class="project__content">{{ project.content }}</p>
      <p class="project__techno">{{ project.techno }}</p>

      <p class="project__caseStudy">{{ project.caseStudy }}</p>
      <div class="links__container">
        <a
          v-if="project.lien"
          :href="project.lien"
          class="project__link"
          target="_blank"
          >Visiter</a
        >
        <router-link class="router__link" :to="{ name: 'Portfolio' }">
          <BaseIcon name="arrow-left" :width="18" :height="18" />
          Portfolio
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      project: {}
    };
  },
  metaInfo() {
    return {
      title: `${this.project.title}`,
      meta: [
        {
          name: `${this.project.title}`,
          content: this.project.caseStudy.slice(0, 100)
        }
      ]
    };
  },
  created() {
    this.project = this.$store.state.projects.find(
      project => project.id === this.id
    );
  }
};
</script>

<style lang="scss" scoped></style>
